import React, { useContext } from "react";
import PropTypes from "prop-types";

import {
	Accordion,
	Card,
	useAccordionToggle,
	AccordionContext,
	Button,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faMinus,
} from "@fortawesome/free-solid-svg-icons";


export default function AccordeonConteudo({ title, children, index }) {
	AccordeonConteudo.propTypes = {
		title: PropTypes.string,
		index: PropTypes.number,
		children: PropTypes.object,
	};

	// Lógica relativa aos toggles para expandir
	function ContextAwareToggle({ children, eventKey, callback }) {
		const currentEventKey = useContext(AccordionContext);
		const decoratedOnClick = useAccordionToggle(
			eventKey,
			() => callback && callback(eventKey)
		);
		const isCurrentEventKey = currentEventKey === eventKey;

		return (
			<>
				<Button
					className="w-100 pb-2"
					variant="link"
					onClick={decoratedOnClick}
				>
					<span>
						<h4 className="fgterciary-gradient pt-1 r-1 text-lowercase font-italic h5 float-left">
							{children}
						</h4>
						{isCurrentEventKey ? (
							<FontAwesomeIcon icon={faMinus} className="float-right mr-3 pt-2 h3" />
						) : (
							<FontAwesomeIcon icon={faPlus} className="float-right mr-3 pt-2 h3" />
						)}
					</span>
				</Button>
			</>
		);
	}

	return (
		<>
			<Card>
				<Card.Header className="p-0 m-0 accordion-header">
					<ContextAwareToggle eventKey={index == 0 ? "0" : `"${index}"`}>
						{title}
					</ContextAwareToggle>
				</Card.Header>
				<Accordion.Collapse eventKey={index == 0 ? "0" : `"${index}"`}>
					<Card.Body>{children}</Card.Body>
				</Accordion.Collapse>
			</Card>
		</>
	);
}
